import axios from 'axios'

let baseURL

if (window.location.hostname.includes('localhost') || window.location.hostname.includes('192.168')) {
    baseURL = 'http://192.168.1.8/avto-obuchenie-2024/public/api/'
}
else if (window.location.hostname.includes('ngrok-free.app')) {
    baseURL = 'https://364c-87-254-168-178.ngrok-free.app/avto-obuchenie/public/api/'
}
else {
    baseURL = 'https://aos2.danielstandard.com/api/'
}

const Api = axios.create({ baseURL })

Api.interceptors.request.use((config) => {
    const token = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')).user.token : ''

    config.params = config.params || {}

    config.headers['X-Path'] = window.location.pathname + window.location.search

    config.headers['Authorization'] = `Bearer ${token}`

    config.headers['ngrok-skip-browser-warning'] = 'test';

    return config
})

export default Api